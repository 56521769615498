
export const staticRoutes = [
    '/',
    'racas',
    'quiz',
    'agendar-visita',
    'login',
    'ponto',
    'cadastro-tutor',
    'cadastro-pet',
    'upload-racas',
    'mascotes',
    'no-local',
    'pagamentos',
    'registros',
    'usuarios',
    'not-found'
  ];
